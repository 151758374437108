import style from './loadingCard.module.scss';

import Spinner from 'components/Spinner';

import WebMdIgniteLogo from 'assets/images/webmd-ignite-logo.svg';

const LoadingCard = (props) => {
    const { message } = props;

    return (
        <div className={style.card}>
            <div className={style.contents}>
                <div>
                    <img src={WebMdIgniteLogo} alt="" />
                </div>
                <div>
                    <Spinner />
                </div>
                <div className={style.message}>{message}</div>
            </div>
        </div>
    );
};

export default LoadingCard;
